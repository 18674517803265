import React from "react";
import styled from "styled-components";

import One from "../../svg/numbers/num-contour-01.svg";
import Two from "../../svg/numbers/num-contour-02.svg";

import { Paragraph } from "../../components/PageElements/StaticElements";

const AlertApiHttp = () => {
  return (
    <Wrapper>
      <Container>
        <h1>Information importante</h1>
        <Sub>
          Fermeture redirection <span>www.smsmode.com/fr/1.5</span>
        </Sub>
        <Paragraph style={{ marginBottom: "20px" }}>
          La fermeture de la redirection www.smsmode.com/fr/1.5 sera effective à
          compter du <span>1er août 2022</span>.
        </Paragraph>
        <h2>Quelles sont les actions à mettre en œuvre de votre côté ?</h2>
        <Paragraph style={{ marginBottom: "20px" }}>
          <div style={{ marginTop: "20px", display: "flex" }}>
            <SvgWrapper>
              <One />
            </SvgWrapper>
            <p>
              Merci de vous assurer que votre logiciel client ou application est{" "}
              <span style={{ fontWeight: "500" }}>
                en mesure de fonctionner sans l’utilisation du protocole SSLv3.
              </span>{" "}
              Pour des raisons de sécurité, le domaine api.smsmode.com ne
              supportera plus ce protocole.
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <SvgWrapper>
              <Two />
            </SvgWrapper>
            <p>
              Si vous utilisez un pare-feu sortant afin de sécuriser vos
              échanges, veuillez vous assurer{" "}
              <span style={{ fontWeight: "500" }}>
                d'ajouter l'IP 31.170.8.215 (domaine api.smsmode.com) dans votre
                whitelist afin de pouvoir appeler correctement notre API HTTP.
              </span>
            </p>
          </div>
          Ces vérification effectuées, il vous faudra changer dans votre
          environnement l'URL de requête de <span>www.smsmode.com/fr/1.5</span>{" "}
          vers <span>api.smsmode.com/http/1.6</span>.
        </Paragraph>
        <h2>Pourquoi cette fermeture ?</h2>
        <Paragraph>
          Le domaine api-old.smsmode.com était un domaine de transition afin de
          laisser le temps à nos clients de migrer vers le nouveau domaine
          api.smsmode.com. Le domaine api-old.smszmode.com supporte encore le
          protocole SSLv3 qui est très obsolète et expose à certaines
          vulnérabilités comme :
          <br />
          <Flex>
            <Circle />
            L'attaque POODLE
          </Flex>
          <Flex>
            <Circle />
            OpenSSL padding Oracle
          </Flex>
          Il est donc dans le devoir de smsmode© d'assurer la sécurité des
          envois de nos clients en faisant le nécessaire pour ne pas être éxposé
          à ces potentiels dangers. Le support smsmode© se tient à votre
          disposition pour toute question complémentaire sur le chat ou en nous
          écrivant à support@smsmode.com.
        </Paragraph>
        <Sub
          style={{
            fontWeight: "300",
            fontSize: "18px",
            margin: "40px 0 0 0"
          }}
        >
          Le support smsmode<sup>©</sup> se tient à votre disposition pour toute
          question complémentaire sur le chat ou en nous écrivant à{" "}
          <span>support@smsmode.com</span>.
        </Sub>
      </Container>
    </Wrapper>
  );
};

const Sub = styled.h2`
  text-align: center !important;
  color: ${({ theme }) => theme.text} !important;
  margin-bottom: 40px;
  & > span {
    font-weight: 900;
  }
`;

const SvgWrapper = styled.div`
  height: 80px;
  width: 80px;
`;

const Flex = styled.div`
  display: flex;
`;

const Circle = styled.p`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #30cd6d;
  margin: 10px;
`;

const Wrapper = styled.div`
  height: calc(100vh - 110px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  @media (max-width: 800px) {
    margin-top: 120px;
  }
`;

const Container = styled.div`
  height: calc(100vh - 160px);
  padding-top: 20px;
  background-color: ${({ theme }) => theme.second};
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  max-width: 800px;
  width: 100%;
  padding: 15px;
  overflow-y: scroll;
  & > h1 {
    font-size: 35px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
  }
  & > h2 {
    color: #52b474;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  @media (max-width: 800px) {
    height: auto;
  }
`;

export default AlertApiHttp;
